<template>
  <v-scale-transition mode="in-out">
    <v-row
      v-if="isAuthenticated"
      no-gutters
      class="mx-auto py-0 flex-column"
    >
      <tab-header
        class="mb-2"
        icon-name="emoticon-happy-outline"
        text="Мої бонуси"
      />

      <v-card
        class="border-green border-radius-10 box-shadow-none"
      >
        <v-card-text
          class="border-green border-radius-10 d-flex flex-column flex-sm-row pt-2
                          pt-sm-3 pb-sm-1 pt-lg-5 pb-lg-3 box-shadow"
        >
          <v-col class="d-flex flex-column align-center col-sm-4 col-md-5">
            <v-card-subtitle class="text--text text-h6 pa-0">
              Вітаємо<span v-if="firstName">, {{ firstName }}!
              </span>
              <span v-else>
                !
              </span>
            </v-card-subtitle>

            <div
              class="border-radius-10 success col-12 py-2 text-h3 white--text text-center mt-2
                      col-sm-auto px-sm-16"
            >
              0₴
            </div>
          </v-col>

          <v-col>
            <p class="text--text">
              Погашаючи кредити вчасно, ви отримаєте знижки на наступні позики, а також будете
              автоматично приймати участь у розіграшах цінних призів.
              <span class="font-weight-medium">
                Ми повідомимо Вас про нарахування бонусів!
              </span>
              По всім питання звертайтеся до Вашого персонального асистента.
            </p>

            <div class="d-flex justify-end mt-lg-n5 mt-xl-n3">
              <v-col class="col-12 col-sm-auto pa-0">
                <v-col class="d-flex pa-0 justify-md-center">
                  <v-icon class="text--text d-md-block mr-1">
                    mdi-face-agent
                  </v-icon>
                  <a
                    href="tel:+38 044 593 66 33"
                    class="text--text text-decoration-none hover-link font-weight-medium subtitle-2
                        pt-1"
                  >+38 044 593 66 33</a>
                </v-col>
                <span class="caption">
                  Служба турботи про клієнтів
                </span>
              </v-col>
            </div>
          </v-col>
        </v-card-text>
      </v-card>
    </v-row>
  </v-scale-transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TabHeader from '@/components/common/TabHeader';
import pageLoaderMixin from '../mixins/pageLoaderMixin';

export default {
  name: 'UserBonuses',
  components: { TabHeader },
  title: 'Мої бонуси',
  mixins: [pageLoaderMixin],
  computed: {
    firstName() {
      return this.$store.state.clientInfo.clientData.firstName;
    },
    ...mapGetters('auth', [
      'isAuthenticated',
    ]),
  },
  beforeMount() {
    if (!this.firstName) {
      this.getClientMainInfo('firstName')
        .then(() => this.hidePagePreloader())
        .catch((err) => console.error(err));
    } else {
      this.hidePagePreloader();
    }
  },
  methods: {
    ...mapActions('clientInfo', [
      'getClientMainInfo',
    ]),
  },
};
</script>

<style lang="scss" scoped>
</style>
